import $ from 'jquery';

window.jQuery = $;

import 'what-input';
import './lib/foundation-explicit-pieces';

$(document).ready(function () {
    $(this).foundation();
});
